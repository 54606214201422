import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { InsightsLinkProps, SFHero } from 'suf_storybook'
import { Query } from '../generated/gatsby.types'
import { OverlayContext } from '../components/OverlayContext'
import InsightsResultsWrapper from '../components/insightsResultsWrapper'
import Main from '../components/main'
import SEO from '../components/seo'

import '../../static/_css/insights-listing-hero.css'
import { parseBannerProps } from '../lib'

type Props = {
  data: Query
}

const Insights = ({ data }: Props) => {
  const { hasMarketo } = useContext(OverlayContext)
  const { contentfulBanner: banner } = data

  const RenderLink = ({ className = '', title, slug }: InsightsLinkProps) => (
    <Link className={className} to={slug}>
      {title}
    </Link>
  )
  return (
    <div className="insights-listing">
      <Main isInsights>
        <SEO
          title="Insights :: Sustainable Fitch"
          description="Insights page"
          type="gatsby"
        />
        <SFHero
          {...parseBannerProps(banner)}
          hasMarketo={hasMarketo}
          isActionBarOpen={false}
        />
        <section className="section section--spacing-below">
          <div className="sf-wrapper--1">
            <InsightsResultsWrapper
              items={[]}
              renderLink={RenderLink}
              reportType="insights/esg-rating-report"
            />
          </div>
        </section>
      </Main>
    </div>
  )
}

export const query = graphql`
  query EsgRatingReports {
    contentfulBanner(description: { eq: "SF Insights Banner" }) {
      ...ContentfulBannerFragment
    }
  }
`

export default Insights